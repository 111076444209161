














































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import FormItems from '@/components/shared/FormItems.vue'
import FormItemsOption from '@/types/FormItemsOption'
import { RecipientWithComment } from '@/types/Recipient'
import { regexToPatternString } from '@/data/Regex'
import { phoneCodeKeyCountries } from '@/data/Countries'
import { countryMasterCodes } from '@/data/masterCodes/countryMasterCodes'
import ReceiveMethod from '@/enums/ReceiveMethod'

@Component({
  components: { FormItems }
})
export default class TransferDetailModal extends Vue {
  @Prop(Object) field!: RecipientWithComment

  countryMasterCodeList: Array<{label: string; value: string}> = this.field.remittance_method_type === ReceiveMethod.USD_ANYWHERE 
    ? [] 
    : Object.keys(countryMasterCodes[this.field.received_country]).map(bank =>
    ({ label: `${bank} (${countryMasterCodes[this.field.received_country][bank]})`, value: countryMasterCodes[this.field.received_country][bank].toString() }))

  recipientOptions: Array<Array<FormItemsOption>> = [
    [
      { prop: 'first_name', label: 'First Name', type: 'text', value: undefined },
      { prop: 'middle_name', label: 'Middle Name', type: 'text', value: undefined },
      { prop: 'last_name', label: 'Last Name', type: 'text', value: undefined },
      { prop: 'birth', label: 'Birth', type: 'text', value: undefined },
      {
        prop: 'phone_code',
        label: 'Phone Code',
        type: 'select',
        options: phoneCodeKeyCountries.map(country => {
          return { label: `${country.iso} (${country.phone_number_code})`, value: Number(country.phone_number_code) }
        }),
        value: undefined
      },
      { prop: 'phone_number', label: 'Mobile', type: 'text', value: undefined }
    ],
    [
      { prop: 'remittance_method_type', label: 'Remittance Method Type ID', type: 'text', value: undefined },
      { prop: 'remittance_method_data.debit_card_number', label: 'Debit Card Number', type: 'text', value: undefined },
      {
        prop: 'master_code',
        label: 'Master Code',
        filterable: true,
        type: 'select',
        options: this.countryMasterCodeList,
        value: undefined
      }
    ],
    [
      { prop: 'remittance_method_data.bank_account_type', label: 'Account Type', type: 'text', value: undefined },
      { prop: 'remittance_method_data.bank_account_number', label: 'Account Number', type: 'text', value: undefined },
      { prop: 'remittance_method_data.bank_branch', label: 'Branch', type: 'text', value: undefined },
      { prop: 'remittance_method_data.bank_iban', label: 'IBAN', type: 'text', value: undefined },
      { prop: 'remittance_method_data.bank_routing_number', label: 'Routing Number', type: 'text', value: undefined },
      { prop: 'remittance_method_data.bank_ifsc', label: 'IFSC', type: 'text', value: undefined },
      { prop: 'remittance_method_data.bank_bsb_code', label: 'BSB Code', type: 'text', value: undefined },
      { prop: 'remittance_method_data.bank_swift', label: "Recipient's Swift Code", type: 'text', value: undefined },
      { prop: 'remittance_method_data.bank_sort_code', label: 'Sort Code', type: 'text', value: undefined },
      { prop: 'remittance_method_data.corresponding_bank_swift', label: 'Correspondent Bank Swift Code', type: 'text', value: undefined },
      { prop: 'remittance_method_data.bank_transit_code', label: 'Transit Code', type: 'text', value: undefined },
      { prop: 'remittance_method_data.payment_gateway_wing_money_account_number', label: 'Wing Money Number', type: 'text', value: undefined }
    ],
    [
      { prop: 'line1', label: 'Address', type: 'text', value: undefined },
      { prop: 'city', label: 'City', type: 'text', value: undefined },
      { prop: 'region', label: 'Region', type: 'text', value: undefined },
      { prop: 'postal_code', label: 'Postal Code', type: 'text', value: undefined }
    ],
    [
      { prop: 'analogue_comment', formItemClass: 'analogue-comment-textarea', label: '수취인 앞 전달할 내용', type: 'textarea', value: undefined }
    ]
  ]

  optionalRule: FormRule = { required: false, message: 'invalid format' }
  recipientRules: Dictionary<Array<FormRule>> = {
    birth: [{ ...this.optionalRule, pattern: regexToPatternString('birth_date') }],
    phone_number: [{ ...this.optionalRule, pattern: regexToPatternString('number') }],
    'remittance_method_data.bank_account_type': [{ ...this.optionalRule, pattern: regexToPatternString('numberEnglish') }],
    'remittance_method_data.bank_account_number': [{ ...this.optionalRule, pattern: regexToPatternString('numberEnglish') }],
    'remittance_method_data.bank_branch': [{ ...this.optionalRule, pattern: regexToPatternString('numberEnglish') }],
    'remittance_method_data.bank_iban': [{ ...this.optionalRule, pattern: regexToPatternString('numberEnglish') }],
    'remittance_method_data.bank_routing_number': [{ ...this.optionalRule, pattern: regexToPatternString('numberEnglish') }],
    'remittance_method_data.bank_ifsc': [{ ...this.optionalRule, pattern: regexToPatternString('numberEnglish') }],
    'remittance_method_data.bank_bsb_code': [{ ...this.optionalRule, pattern: regexToPatternString('numberEnglish') }],
    'remittance_method_data.bank_swift': [{ ...this.optionalRule, pattern: regexToPatternString('numberEnglish') }],
    'remittance_method_data.corresponding_bank_swift': [{ ...this.optionalRule, pattern: regexToPatternString('numberEnglish') }],
    'remittance_method_data.bank_sort_code': [{ ...this.optionalRule, pattern: regexToPatternString('numberEnglish') }],
    'remittance_method_data.bank_transit_code': [{ ...this.optionalRule, pattern: regexToPatternString('numberEnglish') }],
    'remittance_method_data.payment_gateway_wing_money_account_number': [{ ...this.optionalRule, pattern: regexToPatternString('numberEnglish') }],
    line1: [{ ...this.optionalRule, pattern: regexToPatternString('address_line1') }],
    city: [{ ...this.optionalRule, pattern: regexToPatternString('english') }],
    region: [{ ...this.optionalRule, pattern: regexToPatternString('english') }],
    postal_code: [{ ...this.optionalRule, pattern: regexToPatternString('numberEnglish') }],
  }

  onClickCancel () {
    this.$emit('cancel')
  }

  async formValidate () {
    try {
      return await (this.$refs.updateForm as Vue & { validate: () => boolean | any }).validate()
    } catch (error) {
      return error
    }
  }

  async onSubmit () {
    const isValidate: boolean = await this.formValidate()
    if (!isValidate) return
    this.$emit('submit', this.field)
  }
}
