import { Remittance, TableElementRemittance } from '@/types'
import RemittanceSearch from '@/types/apiRequest/RemittanceSearch'
import { Recipient } from '@/types/Recipient'
import RemittanceStatus from '@/types/apiRequest/RemittanceStatus'
import RemittanceStatusEnum from '@/enums/RemittanceStatus'
import { CalculateResult } from '@/lib/calculator/CalculateResult'
import { FxRates } from '@/adapters/fxRates/FxRates'
import { GetFxRates } from '@/adapters/fxRates/GetFxRates'
import DataTableOption, { ButtonType } from '@/types/DataTableOption'
import { FileTransferHandler } from '@/lib/FileTransferHandler'
import { Message } from 'element-ui'
import { formattersMap } from '@/lib/Utils'
import { remittanceStatusLabel } from '@/data/RemittanceStatusLabel'
import dayjs from 'dayjs'
import MethodData from '@/types/MethodData'
import { ExternalRemittance } from '@/types/Remittance'
import { ITransfersUseCase, TransfersUseCase } from '@/usecase/TransfersUseCase'
import RemittancePartner from '@/types/apiRequest/RemittancePartner'
import store from '@/store'
import { CalculatorSourceType, Calculator, ICalculatorSource } from '@/lib/calculator/Calculator'

export interface ITransfersPresentation {
  remittanceList: Array<Remittance>
  totalCount: number

  tableData: Array<TableElementRemittance>
  tableOptions: Dictionary<DataTableOption>

  transfersUseCase: ITransfersUseCase

  getInvoiceButton(base64StringFile: string, id: string): ButtonType | undefined
  getCalculateResult(remittance: Remittance): Promise<CalculateResult | undefined>

  convertMethodData(recipient: Recipient): MethodData
  convertRemittanceTableElement(remittance: Remittance): Promise<TableElementRemittance>

  updateRemittanceStatus(updateRemittanceStatusParam: RemittanceStatus): Promise<any>
  updateRemittancePartner(data: RemittancePartner): Promise<any>
  requestTransfer(remittanceSearchParam: RemittanceSearch, adminMenuType: any): Promise<void>
}

export class TransfersPresentation implements ITransfersPresentation {
  remittanceList: Array<Remittance> = []
  totalCount = 0

  tableData: Array<TableElementRemittance> = []
  tableOptions: Dictionary<DataTableOption> = {
    transfer_id: { type: 'button', label: 'Transfer ID', className: 'transfer_id_column' },
    group_id: { type: 'text', label: 'Group ID' },
    corp: { type: 'object', label: 'Corp' },
    received: { type: 'object', label: 'Received' },
    recipient: { type: 'object', label: 'Recipient' },
    amount: { type: 'object', label: 'Amount' },
    status: { type: 'object', label: 'Status' },
    external_transfer: { type: 'object', label: 'External Transfer' },
    deposit_at: { type: 'object', label: 'Deposit At' },
    other_info: { type: 'object', label: 'Other Info' }
  }

  transfersUseCase: ITransfersUseCase = new TransfersUseCase()

  getInvoiceButton(base64StringFile: string, id: string): ButtonType | undefined {
    if (!base64StringFile) return
    return {
      label: '인보이스',
      icon: 'el-icon-download',
      size: 'x-mini',
      class: 'invoice-btn',
      callback: () => {
        const fileTransferHandler = FileTransferHandler.getInstance()
        const blobUrl: string = fileTransferHandler.getBase64ObjectUrl(base64StringFile)
        if (!blobUrl) {
          return Message({
            message: '알 수 없는 인보이스 파일입니다.',
            type: 'error'
          })
        }
        fileTransferHandler.download(blobUrl, `${id}_invoice`)
      }
    }
  }

  async getCalculateResult(remittance: Remittance): Promise<CalculateResult | undefined> {
    const fxRates: FxRates = await GetFxRates.get(remittance.fx_task_id)
    const calculateSource: ICalculatorSource = {
      type: CalculatorSourceType.TRANSFER_HISTORY,
      remittanceHistoryGroupDetail: remittance
    }
    const calculator: Calculator = new Calculator(fxRates)
    calculator.setSource(calculateSource)
    return calculator.calculate()
  }

  convertMethodData(recipient: Recipient): MethodData {
    const fullName = `${recipient.first_name} ${recipient.middle_name} ${recipient.last_name}`
    const fullAddress = recipient.line1
      ? `${recipient.line1}, ${recipient.city}. ${recipient.region} (${recipient.postal_code})`
      : ''
    return {
      recipient: {
        id: recipient.id,
        corp_pid: recipient.corp_pid,
        full: fullName,
        birth: recipient.birth,
        phone_code: recipient.phone_code,
        phone_number: recipient.phone_number,
        address: {
          line1: recipient.line1,
          city: recipient.city,
          region: recipient.region,
          postal_code: recipient.postal_code,
          country: recipient.received_country,
          full: fullAddress
        }
      },
      bank: {
        account_type: recipient.remittance_method_data.bank_account_type,
        account_number: recipient.remittance_method_data.bank_account_number,
        account_branch: recipient.remittance_method_data.bank_branch,
        external: {
          iban: recipient.remittance_method_data.bank_iban,
          routing_number: recipient.remittance_method_data.bank_routing_number,
          ifsc: recipient.remittance_method_data.bank_ifsc,
          bsb_code: recipient.remittance_method_data.bank_bsb_code,
          swift: recipient.remittance_method_data.bank_swift,
          corresponding_bank_swift: recipient.remittance_method_data.corresponding_bank_swift,
          sort_code: recipient.remittance_method_data.bank_sort_code,
          transit_code: recipient.remittance_method_data.bank_transit_code
        }
      }
    }
  }

  async convertRemittanceTableElement(remittance: Remittance): Promise<TableElementRemittance> {
    const recipient: Recipient = remittance.recipient
    const fullName = recipient.name || `${recipient.first_name} ${recipient.middle_name} ${recipient.last_name}`
    const status: RemittanceStatusEnum = remittance.remittance_status
    const calculated: CalculateResult | undefined = await this.getCalculateResult(remittance)
    const fee = !calculated ? 0 : calculated.commission
    const fixedFee = !calculated ? 0 : calculated.fixedCommission
    const totalFee = !calculated ? 0 : calculated.totalCommission
    const file: ButtonType | undefined = this.getInvoiceButton(remittance.file, remittance.id)
    const depositStatus: Array<RemittanceStatusEnum> = [RemittanceStatusEnum.COMPLETE]
    const refundStatus: Array<RemittanceStatusEnum> = [RemittanceStatusEnum.REFUND]
    const isDepositStatus = depositStatus.includes(status)
    const isRefundStatus = refundStatus.includes(status)
    const statusButton: ButtonType | undefined = isDepositStatus || isRefundStatus ? {
      label: '영수증',
      class: 'receipt-btn',
      callback: () => {
        this.transfersUseCase.makeIndividualReceipt(remittance, isRefundStatus)
      }
    } : undefined
    const statusObject = statusButton
      ? { _text: remittanceStatusLabel[status], _button: statusButton }
      : { _text: remittanceStatusLabel[status] }
    const tableElementRemittance: TableElementRemittance = {
      transfer_id: {
        label: remittance.id,
        callback: () => {
          store.commit('showTransferDetailModal', { transferId: remittance.id, remittanceList: this.remittanceList })
        }
      },
      group_id: remittance.group_code,
      corp: {
        id: remittance.corp_id,
        name: remittance.corp_name
      },
      received: {
        country: recipient.received_country,
        bank: recipient.master_code_label,
        bank_code: recipient.master_code
      },
      recipient: {
        corp_no: recipient.id,
        corp_pid: recipient.corp_pid,
        name: fullName,
        nationality: recipient.nationality
      },
      amount: {
        base_amount: `${formattersMap.number(remittance.base_amount.balance)} ${remittance.base_amount.currency}`,
        receive_amount: `${formattersMap.number(remittance.receive_amount.balance)} ${
          remittance.receive_amount.currency
        }`,
        send_amount: `${formattersMap.number(remittance.send_amount.balance)} ${remittance.send_amount.currency}`,
        fee: `${formattersMap.number(fee)} ${remittance.send_amount.currency}`,
        fixed_fee: `${formattersMap.number(fixedFee)} ${remittance.send_amount.currency}`,
        _text: `(${formattersMap.number(totalFee)} ${remittance.send_amount.currency})`,
        fx_id: remittance.fx_task_id
      },
      status: statusObject,
      external_transfer: undefined,
      deposit_at: {
        date: dayjs(remittance.deposited_at).format('YYYY-MM-DD HH:mm:ss'),
        _button: {
          label: 'Method Data',
          class: 'method-data-btn',
          callback: () => {
            store.commit('setTextObjectContent', {
              title: 'Method Data',
              content: this.convertMethodData(recipient),
              modal: true
            })
          }
        }
      },
      other_info: {
        created_at: dayjs(remittance.created_at).format('YYYY-MM-DD HH:mm:ss'),
        updated_at: dayjs(remittance.updated_at).format('YYYY-MM-DD HH:mm:ss')
      }
    }
    if (file) tableElementRemittance.received._button = file
    const externalRemittances = remittance.external_remittance_list
    if (externalRemittances.length) {
      const singleExternalRemittance: ExternalRemittance = externalRemittances[0]
      singleExternalRemittance.description = singleExternalRemittance.description.length ? `<span class="color-primary">${singleExternalRemittance.description}</span>` : ``
      singleExternalRemittance.created_at = dayjs(singleExternalRemittance.created_at).format('YYYY-MM-DD HH:mm:ss')
      singleExternalRemittance.updated_at = dayjs(singleExternalRemittance.updated_at).format('YYYY-MM-DD HH:mm:ss')
      tableElementRemittance.external_transfer = singleExternalRemittance
      if (externalRemittances.length > 1) {
        tableElementRemittance.external_transfer._button = {
          label: 'More',
          callback: () => {
            const externalRemittanceTextObject: Dictionary<ExternalRemittance> = {}
            externalRemittances.forEach(externalRemittance => {
              const assignedExternalRemittance = Object.assign({}, externalRemittance)
              assignedExternalRemittance._button = undefined
              assignedExternalRemittance.description = externalRemittance.description.length ? `<span class="color-primary">${externalRemittance.description}</span>` : ``
              assignedExternalRemittance.created_at = dayjs(externalRemittance.created_at).format('YYYY-MM-DD HH:mm:ss')
              assignedExternalRemittance.updated_at = dayjs(externalRemittance.updated_at).format('YYYY-MM-DD HH:mm:ss')
              externalRemittanceTextObject[assignedExternalRemittance.id] = assignedExternalRemittance
            })
            store.commit('setTextObjectContent', {
              title: 'External Transfer',
              content: externalRemittanceTextObject,
              modal: true
            })
          }
        }
      }
    }
    if (remittance.aml_status) {
      tableElementRemittance.recipient._tag = {
        label: 'AML',
        type: 'danger',
        size: 'mini'
      }
    }
    if (remittance.analogue) {
      tableElementRemittance.external_transfer = {
        _tag: {
          label: 'Analogue',
          type: 'danger',
          size: 'mini'
        }
      }
    } else {
      this.tableOptions.external_transfer.type = 'object'
    }
    return tableElementRemittance
  }

  async updateRemittanceStatus(updateRemittanceStatusParam: RemittanceStatus): Promise<any> {
    return this.transfersUseCase.updateRemittanceStatus(updateRemittanceStatusParam)
  }

  updateRemittancePartner(data: RemittancePartner): Promise<any> {
    return this.transfersUseCase.updateRemittancePartner(data)
  }

  async requestTransfer(remittanceSearchParam: RemittanceSearch, adminMenuType: any): Promise<void> {
    const response = await this.transfersUseCase.requestTransfer(remittanceSearchParam, adminMenuType)
    this.remittanceList = response.list
    const newTableData: Array<TableElementRemittance> = []
    for (const remittance of this.remittanceList) {
      const tableElement = await this.convertRemittanceTableElement(remittance)
      newTableData.push(tableElement)
    }
    this.tableData = newTableData
    this.totalCount = response.total_count
  }
}
